/** Here to Work: Carousel pits **/

import React from "react";
import { gsap } from "gsap"
import { preloadImages } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { showText, hideText, positionText, resetAnimationDelay } from "../../helpers"
import Carousel from "../../components/media/carousel";
import SlideshowController from "../../components/media/slideshow-controller";

let globalState, data, preloadData, slideshowData, autoAdvanceInterval;

class HereToWorkPage1 extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[1];
    preloadData = globalState.assets[2];

    slideshowData = [
      {
        file: globalState.cdnUrl + data.slide1, 
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide2,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide3,
        fileType: 'image',
      },
      {
        file: globalState.cdnUrl + data.slide4, 
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide5, 
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide6, 
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide7, 
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide8, 
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide9, 
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide10, 
        fileType: 'image'
      }
    ]

    

    //refs
    this.fullscreenBg = null;
  }

  state = {
    textVisible: false,
    currentSlide: 1,
    autoAdvance: 5000
  }

  
  

  componentDidMount = () => {
    
    setTimeout(()=>{
      //this.refs.carouselRef.playVideos();
    }, globalState.transitionDuration);

    //Set autoAdvance
    this.setAutoAdvance();

    //position text
    positionText(this.refs.text1Ref, "left", "bottom", globalState.textPosition.leftBottom);
    

    //show text
    gsap.to(this.refs.text1Ref, 0.15, { autoAlpha: 1.0 });
    
    //add base tracks
    globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1); //pits track

    //add chapter base track
    globalState.baseAudioRef.updateTrack(2, globalState.cdnUrl + data.soundfile2); //chapter base track


    
    //Preload assets from next page
    preloadImages([
      globalState.cdnUrl + preloadData.text1, 
      globalState.cdnUrl + preloadData.text2, 
      globalState.cdnUrl + preloadData.background, 
    ], this.preloadCallback);

    //update the carousel 
    this.refs.carouselRef.updateCurrentSlide(this.state.currentSlide);

  }

  preloadCallback = () =>{
    this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }

  componentDidUpdate() {
    this.refs.carouselRef.updateCurrentSlide(this.state.currentSlide);
  }
  

  componentWillUnmount() {
    
    //Kill any tweens
    gsap.killTweensOf(this.refs.text1Ref);
    
    //remove base track
    //globalState.baseAudioRef.updateTrack(1, false);
    
    //Clear AutoAdvanceInterval
    clearInterval(autoAdvanceInterval);
  }

  

  toggleTextDisplay = (event) => {

    //toggle text visibility
    this.setState({textVisible: !this.state.textVisible});

    //reset the animation delay
    resetAnimationDelay();

    if(this.state.textVisible){
      //show text
      showText(this.refs.text1Ref, "left", "bottom", globalState.textPosition.leftBottom);
      

    } else {
      //hide text
      hideText(this.refs.text1Ref, "left");
      
    }
  }

  isTransitioning = () => {
    //this.roadVideoRef.pauseVideo();
    //this.refs.carouselRef.pauseVideos();
  }

  //Set AutoAdvance
  setAutoAdvance = () => {
    if (this.state.autoAdvance) {
      autoAdvanceInterval = setInterval(() => {
        let nextSlide = this.state.currentSlide + 1;

        if (nextSlide > slideshowData.length) {
          nextSlide = 1;
        }
        this.setState({ currentSlide: nextSlide });
      }, this.state.autoAdvance);
    }
  }

  //Stop AutoAdvance
  stopAutoAdvance = () => {
    this.setState({ autoAdvance: false });
    clearInterval(autoAdvanceInterval);
  }

  //Slideshow controls
  handleNextClick = () => {
    let nextSlide = this.state.currentSlide + 1;

    if (nextSlide > slideshowData.length) {
      nextSlide = 1;
    }

    this.setState({ currentSlide: nextSlide });
    this.stopAutoAdvance();
  }
  handleBackClick = () => {
    let nextSlide = this.state.currentSlide - 1;

    if (nextSlide < 1) {
      nextSlide = slideshowData.length;
    }

    this.setState({ currentSlide: nextSlide });
    this.stopAutoAdvance();
  }

  render() {

    return (

      <>

        <link rel="prefetch" href="/here-to-work/2" />
        
        <PrevNext globalState={globalState} ref="prevNextRef" 
        nextLocation="/here-to-work/2" prevLocation="/here-to-work"
        isTransitioning={this.isTransitioning} />

        {data && (
        <div className="fullpage-wrapper">
          <SEO title="Pine Point - Here To Work" />

          <div className="text-wrapper">
            <div className="text text--left-bottom" ref="text1Ref">
              <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginLeft: "0", marginBottom: "160px" }} onClick={this.toggleTextDisplay} alt="PINE POINT I SURROUNDED BY DOZENS OF OTHERWORLDLY PITS. Some are ringed with crumbled rock, others filled with cerulean pools. It’s all very fountain-of-youth meets post-nuclear-accident – an industrial beauty you almost feel guilty for noticing." />
            </div>
          </div>
          <SlideshowController xPos="410px" yPos={"calc(100vh - 190px)"} handleNextClick={this.handleNextClick} handleBackClick={this.handleBackClick} state={this.state} totalSlides={slideshowData.length} />
          <Carousel ref="carouselRef" data={slideshowData} globalState={globalState} fullscreen={true} position={{x:0, y:0}} size="" state={this.state} />
          
        </div>
        )}
      </>
    );
  }


};

export default HereToWorkPage1
